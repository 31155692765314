import React, { FC } from 'react';
import { NativeAppEvents } from '../nativeApp.events';
import { nativeAppGlobalStyles } from '../nativeApp.globalStyles';
import { GooglePlayIcon } from './components/GooglePlayIcon';

interface Props {
  source: string;
  dataQa?: string;
}

const googleLink = 'https://play.google.com/store/apps/details?id=com.mateacademyapp';

export const GooglePlayButton: FC<Props> = (props) => {
  const {
    source,
    dataQa,
    children,
  } = props;

  return (
    <a
      onClick={() => NativeAppEvents.sendEvent(
        NativeAppEvents.events.appStoreButtonClicked,
        {
          source,
          provider: 'google',
        },
      )}
      href={`${googleLink}&referrer=utm_source%3Dapp_promo%26utm_medium%3D${source}_button%26utm_campaign%3Ddownload_app%26anid%3Dadmob`}
      rel="noopener noreferrer"
      target="_blank"
      className={nativeAppGlobalStyles.storeButton}
      data-qa={dataQa}
    >
      {children || (
        <GooglePlayIcon className={nativeAppGlobalStyles.storeIcon} />
      )}
    </a>
  );
};
