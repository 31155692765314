import React, { FC } from 'react';
import { NativeAppEvents } from '@/components/nativeApp/nativeApp.events';
import { AppleStoreIcon } from './components/AppleStoreIcon';
import { nativeAppGlobalStyles } from '../nativeApp.globalStyles';

interface Props {
  source: string;
  dataQa?: string;
}

const appleLink = 'https://apps.apple.com/app/mate-academy/id1636846508';

export const AppleStoreButton: FC<Props> = (props) => {
  const {
    source,
    dataQa,
    children,
  } = props;

  return (
    <a
      onClick={() => NativeAppEvents.sendEvent(
        NativeAppEvents.events.appStoreButtonClicked,
        {
          source,
          provider: 'apple',
        },
      )}
      href={`${appleLink}?utm_source=app_promo&utm_medium=${source}_button&utm_campaign=download_app`}
      rel="noopener noreferrer"
      target="_blank"
      className={nativeAppGlobalStyles.storeButton}
      data-qa={dataQa}
    >
      {children || (
        <AppleStoreIcon className={nativeAppGlobalStyles.storeIcon} />
      )}
    </a>
  );
};
